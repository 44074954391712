// Body
$body-bg: #ffffff;
$body-color: #4b4b4b;
// Typography
 $font-family-sans-serif: "font-regular","Roboto", Arial, Helvetica, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
// Colors
$white: #ffffff;
$blue: #007bff;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #28a745;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$dark: #343a40;
$alert-danger: $red;
$alert-success: $green;
$primary: $blue;
$success: $green;
$font-weight-base: normal;
$enable-shadows: true;
$enable-transitions: true;

@import "variables";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~gijgo/css/gijgo.min.css";
@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400,700');
// @import 'antd/dist/antd.css';
:host {
  // font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus,
*:active {
  outline: 0 !important;
}

*::placeholder,
.form-control::placeholder {
  color: rgba(0, 0, 0, 0.54) !important;
  opacity: 0.54;
}

body {
  // background-color: rgba(128, 128, 128, 0.15);
  // background-color:#DCDCDC
  color: #ffffff!important;
  background-color:#E6E6FA;

}
.content{
  min-height: calc( 100vh - 55.50px);
}
.button-rounded {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.text {
  font-size: 18px;
}

.colorWhite {
  color: white;
}

.mt13M {
  margin-top: -13px;
}

.wrapper {
  //width: 100%;
  //height: 100%;
  //display: flex;
  align-items: center;
  justify-content: center;
}

.kb-main {
  min-height: calc(100vh - 61px);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-none {
  cursor: default;
}

.text-wrap {
  word-wrap: break-word;
}

.btn-bg-dark {
  color: #fff;
  background-color: #5397BC;
}

.btn-bg-dark:hover {
  color: #fff;
  background-color: #5397BC;
  border-color: #5397BC;
}

.btn-bg-light {
  color: #fff;
  background-color: #6495ED;
  border-color: #6495ED;
}

.btn-bg-light:hover {
  color: #fff;
  // background-color: #7ABDC6;
  // border-color: #7ABDC6;
  background-color: #5e8bdd;
  border-color: #5e8bdd;
}

.btn-bg-color {
  background-color: #a7a6a6;
  color: white;
}

.btn-bg-color:hover {
  background-color: #A8AFB0;
  border-color: #A8AFB0;
  color: white;
}

.btn-red-color {
  background-color: #ff2200;
  color: white;
}

.btn-red-color:hover {
  background-color: #ff0000;
  border-color: #ff0000;
  color: white;
}

.btn-db-color {
  // background-color: #7ABDC6;
 background-color: #1e4779;
  color: white;
}

.btn-db-color:hover {
  background-color: #183a63;
  border-color: #1e4779;
  color: white;
}

.btn-active-color {
  background-color: hsl(36, 90%, 51%);
  color: white;
}

.btn-active-color:hover {
  color: #fff;
  background-color: #DBA34E;
  border-color: #DBA34E;
}


.btn-height-4px {
  height: 4rem;
}

.btn-height-3-3px {
  height: 3.3rem;
}

.btn-height-5px {
  height: 5rem;
}

.btn-height-6px {
  height: 6rem;
}

.btn-height-7px {
  height: 7rem;
}

.btn-width-100px {
  width: 100px !important;
}


.lh-1px {
  line-height: 1rem;
}


.full-height {
  height: calc(100vh - 61px);
}

.scroll-bar {
  overflow-y: scroll;
}

.border-color {
  border-color: #8c8c8c;
}

input[type=file] {
  display: none;
}
.rdt.form-control input{
  color: inherit!important;
  padding: 5px;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

//.color-code {
//  color: #2C8DA2;
//  color: #91EA13;
//  color: #41A669;
//  color: #7E91C8;
//  color: #6EE7B4;
//  color: #4AB8AA;
//  color: #82040A;
//}

.btn-blue-color {
  background-color: #177dd1;
  color: white;
}

.btn-blue-color:hover {
  //background-color: #2d89a2;
  // background-color: #568fbc;
  background-color: #1673c0;
  color: white;
}

.btn-gray-color {
  background-color: #800000;
  color: white;
}

.btn-green-color {
background-color: #0a914d;
color: white;
}

.btn-green-color:hover {
  background-color:#07723c;
  color: white;
}
.btn-dark-blue-color {
  // background-color: #1ABC9C;
  background-color: #236499;
  color: white;
  }

  .btn-dark-blue-color:hover {
    // background-color:#1ABC9C;
    background-color:#174870;
    color: white;
  }

  .btn-brown-color {
    background-color: #800000;
    color: white;
    }

    .btn-brown-color:hover {
      background-color:#570202;
      color: white;
    }
.text-db-color{
 color: #1e4779;

}
.btn-lb-green{
    background-color: #9BB880;
    color: white;

}
.btn-lb-green:hover {
  background-color:#9BB880;
  color: white;
}
.login-circle-color {
  background-color: transparent;
  border-color: #2d89a2;
}

.login-active-circle-color {
  background-color: #2d89a2;
  border-color: #2d89a2;
}

.text-blue {
  color: #4AB8AA;
}

.text-blue-dark {
  color: #5397BC;
}

.text-yellow {
  color: #DBA34E;
}

.text-green {
  color: #41A669;
}

.text-green-light {
  color: #4AB8AA;
}

@media (min-width: 1024px) {
  .col-5-row {
    width: 20%;
  }
}


@media (max-width: 768px) {
  .col-5-row {
    width: 33.33%;
  }
}

@media (max-width: 540px) {
  .col-5-row {
    width: 50%;
  }
}



@media (min-width: 1024px) {
  .col-6-row {
    width: 16.66%;
  }
}


@media (max-width: 768px) {
  .col-6-row {
    width: 25%;
  }
}

@media (max-width: 540px) {
  .col-6-row {
    width: 100%;
  }
}


@media (min-width: 1024px) {
  .col-8-row {
    width: 11.11%;
  }
}


@media (max-width: 768px) {
  .col-8-row {
    width: 25%;
  }
}

@media (max-width: 540px) {
  .col-8-row {
    width: 50%;
  }
}


@media (min-width: 1024px) {
  .col-6-row {
    width: 16.66%;
  }
}


@media (max-width: 768px) {
  .col-6-row {
    width: 25%;
  }
}

@media (max-width: 540px) {
  .col-6-row {
    width: 50%;
  }
}


.btn-sq-lg {
  width: 50px !important;
  height: 50px !important;
}

.btn-sq {
  width: 100px !important;
  height: 100px !important;
  font-size: 10px;
}

.btn-sq-sm {
  width: 50px !important;
  height: 50px !important;
  font-size: 10px;
}

.btn-sq-xs {
  width: 25px !important;
  height: 25px !important;
  padding: 2px;
}


.hiddenRow {
  padding: 0 4px !important;
  background-color: #eeeeee;
  font-size: 13px;
}

.accordian-body span {
  color: #a2a2a2 !important;
}

.card-border-color {
  border-color: #41A669;
}

.btn-out-color {
  border-color: #236499;
}

.btn-out-color:hover {
  border-color: #5397BC;
  background-color: #5397BC;
}

.i-ro {
  cursor: not-allowed;
  pointer-events: none;
}


.preBtn {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
}

preBtn:hover {
  background-color: #ddd;
  color: black;
}

.previous {
  background-color: #f1f1f1;
  color: black;
}

.next {
  background-color: #4CAF50;
  color: white;
  align-content: end;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.camera-btn {
  top: 150px !important;
  left: -100px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}


// Drop down checkbox



.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: normal;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
}

#checkboxes label {
  display: block;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

.menuMultiselect {
  width: 200px;
}

.menuSelectBox {
  position: relative;
}

.menuSelectBox select {
  width: 100%;
  font-weight: normal;
}

.menuSelectBox {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}


#menuCheckboxes {
  display: none;
  border: 1px #dadada solid;
}

#menuCheckboxes label {
  display: block;
}

#menuCheckboxes label:hover {
  background-color: #1e90ff;
}

.dropdown-toggle::after {
  border: 0px;
}

#multiSelect li {
  list-style-type: none;
}





.chip {
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
  padding: 0px 13px;
  border-radius: 16px;
  background-color: #eef2f3;
  margin: 5px 5px;
  display: inline-block;
  outline: none;
}

.chip:focus {
  background-color: #51c5c2;
  color: #fff;
}

.chips.chips-initial input.chip-input {
  display: inline-block;
  float: none;
  width: 100%;
  min-width: 100%;
  border: none;
  outline: none;
}




@media (min-width: 1024px) {
  .col-8-row {
    width: 12.5%;
  }
}


@media (max-width: 768px) {
  .col-8-row {
    width: 25%;
  }
}

@media (max-width: 540px) {
  .col-8-row {
    width: 33.33%;
  }
}



.chip {
  padding: 0 12px;
  background-color: '#a2a2a2';
  border-radius: 100px;
  display: inline-flex;
  margin: 4px;
  color: rgba(0, 0, 0, .87);
  align-items: center;
  height: 32px;
  font-size: 14px;
  border: none;
}

.chip .icon {
  font-size: 16px;
  color: rgba(0, 0, 0, .54);
  width: 20px;
  text-align: center;
}

.chip .icon--leadind {
  margin: 2px 4px 0 -4px;
}

.chip .icon--trailing {
  margin: 2px -4px 0 4px;
  cursor: pointer;

}

.chips__choice .chip.chip--active {
  color: red($color: #000000);
  background-color: grey
}

.chips__filter .chip .chip--check {
  display: none;
}

.chips__filter .chip.chip--active .chip--check {
  display: block;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.modal-dialog-height{
  height:  400px;
  // width: 500px;
}


@media (min-width: 1024px) {
  .col-3-row {
    width: 33.33%;
  }
}


@media (max-width: 768px) {
  .col-3-row {
    width:33.33%;
  }
}

@media (max-width: 540px) {
  .col-3-row {
    width: 100%;
  }
}







































/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 1600;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}
/*

!* :not(:required) hides these rules from IE9 and below *!
.loading:not(:required) {
  !* hide "loading..." text *!
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}
*/

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 600px; /* Set a default minimum width */
  margin-left: -300px; /* Divide value of min-width by 2 */
  background-color: rgb(40, 219, 16); /* Black background color */
  color: #fff; /* White text color */
  text-align: left; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1200; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  top: 30px; /* 30px from the bottom */
}

#snackbar1 {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 600px; /* Set a default minimum width */
  margin-left: -300px; /* Divide value of min-width by 2 */
  background-color: rgb(219, 16, 16); /* Black background color */
  color: #fff; /* White text color */
  text-align: left; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1200; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  top: 30px; /* 30px from the bottom */
}


/* Show the snackbar when clicking on a button (class added with JavaScript) */
.show {
  visibility: visible !important; /* Show the snackbar */

/* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}









//  Drag and drop


#sortable {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 1500px;
}

#sortable li {
  margin: 3px 3px 3px 0;
  padding: 1px;
  float: left;
  width: 100px;
  height: 90px;
  font-size: 16px;
  text-align: center;
}

#sortable2 {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 450px;
}

#sortable2 li {
  margin: 3px 3px 3px 0;
  padding: 1px;
  float: left;
  width: 100px;
  height: 90px;
  font-size: 16px;
  text-align: center;
}
ul:after {
  content: '';
  clear: both;
  display: table;
}
.custom-modal-style {
  width: 2000px;
  height: 802px;
  }




  // React Using CSS

  .w-10 {
    width: 10% !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-35 {
    width: 35% !important;
  }
  .w-40 {
    width: 40% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .bold {
  font-weight: bold;
  }

  .fs-14px {
    font-size: 14px;
  }

  .fs-18px {
    font-size: 18px;
  }
  .fs-21px {
    font-size: 21px;

  }
  .fs-22px {
    font-size: 22px;
  }
  .fs-11px{
    font-size: 11px;
  }
  .italic-text{
    font-style: italic;
  }


  .text-warning{
    color: gold; }

.colorBlack {
  color: black;
}

.colorRed {
  color: red;
}

.colorGreen {
  color: green;
}

.flex-3 {
  flex: 3;
}

.flex-1 {
  flex: 1;
}

input::-webkit-outer-spin-button,

  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;

  }

//TABLE

.table thead{
  border-bottom: 2px solid #5d5d5d;
  th{
    font-family: 'font-bold';
  }
}

.rewardCard{
  border: 1px solid #777777;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  .name-container{
    border-bottom: 1px solid #5f5f5f;
  }
  .name{
    font-family: 'font-medium';
  }
  .points{
  font-family: font-medium;
  }
  .code{
    border: 1px dashed #808080;
    background: #585858;
    font-family: font-bold;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
  }
  .expiryDate{
    font-size: 10px;
  }
}


.card{
  label{
    font-size: inherit!important;
  }
  .card-title{
    font-family: font-medium;
    font-size: 1.3rem;
  }
  .card-body{
    h4{
      font-family: font-medium;
    }
  }
  .card{
    background: #00000033!important;
  }
}



.btn-menuItems{
  background-image: linear-gradient(to bottom left, #344675, #263148, #344675);
  font-family: font-medium;
}
.table-card{
  color: #ffffff;
}
.tab-content .tab-pane{
  color: #ffffff!important;
}
.modal{
  .modal-content{
    .modal-header{
      .modal-title{
        font-family: 'font-medium';
        font-size: 1.3rem;
      }
    }
    .modal-body{
      color: inherit;
    }
  }
}
#collapsibleNavbar .navbar-nav li a{
  font-size: 1rem;
  font-family: 'font-medium';
}
.btn-round{
  font-size: 1rem;
  font-family: 'font-medium';
  min-width: 119px;
}
.white-content{
  body {
    color: #000000!important;

  }
  .nav-link{
    color: inherit!important;
  }
  .card{
    color: #0a0a10;
  }
  .table-card{
    color: #ffffff;
    &.table-unallocated{
      color: $dark;

    }
  }
  .btn-menuItems{
    color: #ffffff;
    &:not(.btn-subcat){
      background-image: linear-gradient(to bottom left, #ffffff, #ffffff, #dcdcdc);
      color: $dark;
      &:hover{
        color: #ffffff;
      }
    }

    &.active{
      color: #ffffff;
    }
  }

  .tab-content .tab-pane{
    color: $dark!important;
  }
  .modal{
    .modal-content{
      .modal-header{
        .modal-title{
        }
      }
      .modal-body{
        color: initial;
      }
    }
  }
}

// small tabs
.tabs-small.nav-tabs > .nav-item > .nav-link {
  color: #ffffff;
  margin: 0;
  margin-right: 5px;
  background-color: transparent;
  border-radius: 30px;
  padding: 5px 17px;
  line-height: 1.5;
}

body {
  overflow-y: auto;
  background-color: #000 !important;
}
main{
  min-height: calc(100vh - 58px);
}
.forced-overflowAuto {
  overflow: auto !important;
}
.roundedEdgesAb {
  border-radius: 7px;
}
html {
  overflow: auto !important;
}
.spacing-20 {
  padding: 20px;
}
.move-bottom-deep {
  z-index: -9;
}
.admin-nav-bar-title {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
.borderBottom {
  border-bottom: 1px solid #fff;
}
.selBorder-width {
  border: 2px solid #0071e3;
}
.border-width {
  border: 1px solid #f1f1f1;
}

.white-content .selBorder-width {
  border: 2px solid #0071e3;
}
.white-content .border-width {
  border: 1px solid #000;
}
.white-content .modalBg {
  background-color: #f1f1f1;
}
.white-content .borderBottom {
  border-bottom: 1px solid #000;
}
input.customSearchBar::placeholder {
  color: #000;
  opacity: 1;
}
.forced-paddingZero {
  padding: 0px 0px !important;
}
.forced-verticalCenter {
  vertical-align: middle !important;
}
.forced-noMargins {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.customSearchBar {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  color: #000 !important;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid #cad1d7;
  border-bottom: 1px solid #cad1d7;
  box-shadow: none;
  padding: 0%;
  padding-left: 10px;
  margin-bottom: 10px;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
/* .navBarHeader[data="blue"], .mainNavbar[data="blue"]{
    background: linear-gradient(0deg,#3358f4,#1d8cf8) !important;
} */
.required:after {
  content: " *";
  color: red;
}
.white-content .mainNavbar {
  background: #fff !important;
}
.navbar.navbar-expand-md.mainNavbar {
  height: 56px;
}
.navBarHeader[data="primary"],
.mainNavbar[data="primary"] {
  background: linear-gradient(0deg, #ba54f5, #e14eca);
}
.navBarHeader[data="green"],
.mainNavbar[data="green"] {
  background: linear-gradient(0deg, #0098f0, #00f2c3);
}
.navBarHeader[data="orange"],
.mainNavbar[data="orange"] {
  background: linear-gradient(0deg, #ff6491, #ff8d72);
}
.navBarHeader[data="red"],
.mainNavbar[data="red"] {
  background: linear-gradient(0deg, #ec250d, #fd5d93);
}
.disable-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.full-width {
  width: 100%;
}
/* .custom-checkbox {
  color: #000;
} */
.form-control {
  background-color: white !important;
}
.forced-white {
  color: #000 !important;
}
.forced-grayText {
  color: rgb(107, 102, 102) !important;
}
.forced-whiteText {
  color: #fff !important;
}
.white-content .forced-whiteText {
  color: #000 !important;
}
.button-header {
  width: 14.5%;
}
.span4 {
  width: 80px;
  float: left;
  margin: 0 8px 10px 8px;
}

.phone {
  margin-top: 15px;
  background: #fff;
}
.tel {
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #9e9e9e;
  border-radius: 0px;
}
.num-pad {
  margin: 0px auto;
  padding-right: 10px;
  padding-top: 20px;
  padding-left: 10px;
  width: 308px;
}

.num {
  background-color: #444;
  border: 1px solid #fff;
  -webkit-border-radius: 999px;
  border-radius: 999px;
  -moz-border-radius: 999px;
  height: 80px;
  /* background-color: #fff; */
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.white-content .hsn-card .card {
  background-color: #f1f1f1 !important;
  color: #ffffff;
}
.radio-size-20 {
  height: 20px !important;
  width: 20px !important;
}
.hsn-card .card {
  background-color: #222 !important;
  color: #ffffff;
}
.num:hover {
  background-color: #9e9e9e;
  color: #fff;
  transition-property: background-color 0.2s linear 0s;
  -moz-transition: background-color 0.2s linear 0s;
  -webkit-transition: background-color 0.2s linear 0s;
  -o-transition: background-color 0.2s linear 0s;
}
.white-content .sigContainer {
  border: 1px solid #f1f1f1;
}
/* Toggle Switch */
html {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -ms-font-smoothing: antialiased !important;
}
.md-switch {
  margin-bottom: 15px;
}
.md-switch .md-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.md-switch .md-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.md-switch input.md-toggle-round + label {
  padding: 2px;
  width: 68px;
  height: 40px;
}
.md-switch input.md-toggle-round + label:before,
input.md-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
.md-switch input.md-toggle-round + label:before {
  right: 1px;
  background: #dddddd;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
  border-radius: 60px;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
}
.md-switch input.md-toggle-round + label:after {
  width: 34px;
  height: 34px;
  margin: auto 4px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
  border-radius: 100%;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}
.md-switch input.md-toggle-round:checked + label:before {
  background-color: #00af4d;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.24);
}
.md-switch input.md-toggle-round:checked + label:after {
  margin-left: 32px;
}
.md-switch input:disabled + label:before {
  background-color: #ddddd;
  cursor: not-allowed;
}
.md-switch input:disabled + label span {
  color: #cccccc;
  cursor: not-allowed;
}
.md-switch input:disabled + label:after {
  background-color: #cccccc;
  cursor: not-allowed;
}
.md-switch.md input.md-toggle-round + label {
  width: 46px;
  height: 25px;
}
.md-switch.md input.md-toggle-round + label:after {
  width: 21px;
  height: 21px;
}
.md-switch.md input.md-toggle-round:checked + label:after {
  margin-left: 24px;
}
.md-switch.md label span {
  margin-left: 45px;
  line-height: 10px;
}
.md-switch.sm input.md-toggle-round + label {
  width: 35px;
  height: 18px;
}
.md-switch.sm input.md-toggle-round + label:after {
  width: 14px;
  height: 14px;
}
.md-switch.sm input.md-toggle-round:checked + label:after {
  margin-left: 20px;
}
.md-switch.sm label span {
  margin-left: 35px;
  line-height: 0px;
}
.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
  border: 1px solid #9e9e9e;
}
.sigPad {
  width: 100%;
  height: 100%;
}
.txt {
  font-size: 30px;
  text-align: center;
  /* margin-top: 15px; */
  font-family: "Lato", sans-serif;
  line-height: 30px;
  color: #fff;
}
.small {
  font-size: 15px;
}
.white-star {
  color: #fff !important;
  border: 1px solid #fff !important ;
}
.rdt.form-control input {
  color: #000;
  border: none;
  outline: none;
  height: 22px;
  transition: height 1s;
  -webkit-transition: height 1s;
}
.rdtTime table {
  color: #000;
}

.card .card-body .card-description,
.card .card-body .card-category {
  color: rgba(255, 255, 255, 0.8) !important;
}

.userDropdown li {
  list-style: none;
}
.navbar-brand .btn {
  margin: 0px !important;
  padding: 0px;
}
.navbar-brand .btn i {
  /*color: #fff;*/
}

#collapsibleNavbar .navbar-nav li {
  margin: 0px 6px !important;
}

.white-content #collapsibleNavbar .navbar-nav li a {
  border: solid 1px rgba(72, 72, 72, 0.2);
}
#collapsibleNavbar .navbar-nav li a {
  border: solid 1px rgba(255, 255, 255, 0.4);
  border-radius: 100px;
  cursor: pointer;
  font-size: 15px;
  background-color: transparent;
  width: 140px;
  text-align: center;
  font-weight: 500;
  padding: 0.3rem 0.7rem !important;
}
#collapsibleNavbar .navbar-nav li {
  margin: 0px;
  padding: 0px !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0.38rem 1.2rem !important;
}
.navbar-expand-lg .navbar-nav .nav-link:hover,
#collapsibleNavbar .navbar-nav li a:hover {
  background-color: #2997ff;
}
/*.white-content #collapsibleNavbar .navbar-nav li a {*/
/*  color: #fff;*/
/*}*/
/*.white-content #collapsibleNavbar .navbar-nav li a:hver {*/
/*  color: #fff;*/
/*  background-color: #2997ff;*/
/*}*/

.navbar-toggler i {
  color: #fff;
}

.my-lg-0.userDropdown {
  padding-left: 0px !important;
}
.userDropdown .nav-item .nav-link {
  color: #fff;
}

.all-center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
body .inputTextTransparent::placeholder #otherTipValue {
  color: #fff !important;
}
.inputTextTransparent {
  color: #fff !important;
  border: 0px solid transparent !important;
  border-color: transparent !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;
  font-size: 1.75rem !important;
  display: flex !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.btn-color {
  border-color: rgba(70, 129, 63, 0.904) !important;
}
/* .responsiveTable{width: 100%;border: solid 0.1px rgba(255, 255, 255, 0.1) !important;; } */

.responsiveTable {
  width: 100%;
  overflow-x: auto;
}
.responsiveTable table {
  background: #464545;
}

.responsiveTable table {
  min-width: 900px;
  background: #353535;
}
.responsiveTableLargetable {
  min-width: 1050px;
}
.btn {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.white-star.customBtnForPassword {
  padding: 0px;
  width: 26px !important;
  height: 26px !important;
}
.white-star.customBtnForPassword.btn {
  padding-left: 6px !important;
  padding-right: 5px !important;
  padding-top: 3px !important;
}

.btn.btn-icon,
.navbar .navbar-nav > a.btn.btn-icon {
  min-width: 1px !important;
}

.btn {
  font-weight: 500 !important;
}

.mainNavbar {
  padding: 0.3rem 0.9375rem !important;
}
.themeChange .fixed-plugin {
  position: static;
  width: auto;
  background: transparent;
}
.themeChange .fixed-plugin .fa-cog {
  padding: 4px 0px;
  font-size: 1.2rem;
  position: relative;
  top: 10px;
}

.headerLogout.dropdown-item {
  padding: 0px 0px;
  display: inline-block;
}
.headerLogout.dropdown-item:hover {
  background: transparent;
}
.headerLogout.dropdown-item:hover i {
  color: #ffff00;
}
.headerLogout i {
  color: #fff;
  position: relative;
  top: 8px;
  font-size: 1.1rem;
}

.themeChange .fixed-plugin .dropdown-menu {
  right: -20px;
  left: auto !important;
  top: 34px !important;
  background: #fff;
  padding: 15px;
}
.themeChange .fixed-plugin li.header-title {
  color: #222a42;
}
.themeChange .fixed-plugin .adjustments-line .togglebutton .label-switch {
  color: #222a42;
}
.themeChange .fixed-plugin hr {
  display: block;
  clear: both;
  overflow: hidden;
  margin-top: 10px;
}

.themeChange .fixed-plugin .dropdown .dropdown-menu:before {
  display: none;
}
.themeChange .fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: -6px;
  width: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  right: 22px;
}

.white-content .themeChange .fixed-plugin .dropdown-menu {
  width: 290px;
  border-radius: 0.1875rem;
  padding: 15px;
  background: linear-gradient(#222a42, #1d253b);
}
.white-content .themeChange .fixed-plugin li.header-title {
  color: #fff;
}
.white-content
  .themeChange
  .fixed-plugin
  .adjustments-line
  .togglebutton
  .label-switch {
  color: #fff;
}
.white-content .themeChange .fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 8px solid #222a42;
}

body .modal.show .modal-dialog {
  -webkit-transform: translate(0, 0%) !important;
  transform: translate(0, 0%) !important;
}
.modal-content .modal-body .form-control {
  color: #fff !important;
}

/* .modal-content .menucat-modalbody.modal-body{background-color: #595959;} */
.modal-content .menucat-modalbody.modal-body .btn.btn-outline-primary {
  background-color: #444 !important;
  background: #444;
  background-image: linear-gradient(to bottom left, #444, #444, #444);
}

.cartListsWithBtn {
  border: solid 1px rgba(255, 255, 255, 0.15);
  height: calc(100vh - 74px);
  float: left;
  width: 100%;
  overflow-y: auto;
}
.mclCard.card .card-footer {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.mclCard.card .card-footer button {
  margin-right: 10px !important;
}

h3.card-category {
  font-size: 1.4375rem !important;
  margin-bottom: 0px !important;
}

.headerRight {
  display: flex;
}

.loginScreen {
  color: #fff;
  font-size: 14px;
}

.cartBottomBtns {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollCart {
  height: calc(100vh - 378px);
}

.cartBackgroundColor {
  background-color: rgba(255, 255, 255, 0);
}
.white-content .cartBackgroundColor {
  background-color: rgba(255, 255, 255, 1);
}
.cartBackgroundColor .table-secondary,
.cartBackgroundColor .table-secondary > th,
.cartBackgroundColor .table-secondary > td {
  background: #263148;
  color: #fff;
}
.cartBackgroundColor .border {
  border: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

.white-content .cartBackgroundColor .border {
  border: 0.0625rem solid rgba(0, 0, 0, 0.1) !important;
}

.cartBackgroundColor .cartHeader {
  color: #fff;
}

.priceColoum {
  padding-right: 17px;
}

.cartBackgroundColor .text-danger {
  color: #fff !important;
}
.cartBackgroundColor .text-success {
  color: #fff !important;
}
.cartBackgroundColor .text-blue-dark {
  color: #fff !important;
}
.cartBackgroundColor .text-blue {
  color: #fff;
}
.cartHeader {
  color: #fff;
}
.cartBackgroundColor .text-db-color {
  color: rgba(255, 255, 255, 0.7);
}

/* .cartBackgroundColor .text-danger {color: #f44b34 !important;}
.cartBackgroundColor .text-success {color: #556bfc !important;}
.cartBackgroundColor .text-blue-dark{color: #556bfc !important;}
.cartBackgroundColor .text-blue{color:#333;}
.cartHeader{color:#000;} */

.subCategoryBtns .btn-green-color {
  background-color: #526dfc !important;
  background-image: linear-gradient(
    to bottom left,
    #526dfc,
    #526dfc,
    #526dfc
  ) !important;
  background-image: -moz-linear-gradient(
    to bottom left,
    #526dfc,
    #526dfc,
    #526dfc
  ) !important;
}
.subCategoryBtns .btn-green-color:hover {
  background-color: #233cc0 !important;
  background-image: linear-gradient(
    to bottom left,
    #233cc0,
    #233cc0,
    #233cc0
  ) !important;
  background-image: -moz-linear-gradient(
    to bottom left,
    #233cc0,
    #233cc0,
    #233cc0
  ) !important;
}

.cartRightMenu .btn-blue-color {
  background-color: #90c859 !important;
  background-image: linear-gradient(
    to bottom left,
    #90c859,
    #90c859,
    #90c859
  ) !important;
  background-image: -moz-linear-gradient(
    to bottom left,
    #90c859,
    #90c859,
    #90c859
  ) !important;
}
.cartRightMenu .btn-blue-color:hover {
  background-color: #4f930c !important;
  background-image: linear-gradient(
    to bottom left,
    #4f930c,
    #4f930c,
    #4f930c
  ) !important;
  background-image: -moz-linear-gradient(
    to bottom left,
    #4f930c,
    #4f930c,
    #4f930c
  ) !important;
}

.scrollCart .text-primary {
  color: #fff !important;
}
.scrollCart .text-dark {
  color: #fff !important;
}

.white-content .scrollCart .text-primary {
  color: #000 !important;
}
.white-content .scrollCart .text-dark,
.white-content .cartBackgroundColor .cartHeader,
.white-content .cartBackgroundColor .text-danger,
.white-content .cartBackgroundColor .text-success {
  color: #000 !important;
}
.white-content .cartBackgroundColor .text-db-color {
  color: #000 !important;
}

.cbbInner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cbbInner > div button {
  width: 180px;
  border-radius: 4px;
}
.cartBottomBtns .cbbInner button {
  border: none;
  margin: 6px 6px 0;
  padding: 8px 10px;
}

.cartBottomBtns .cbbInner button.btn-brown-color:disabled {
  background-color: #143d5e;
  color: #3480bf;
}

#modalForm .modal-content {
  background-color: #212121;
}
#modalForm .modal-content .modal-body .form-control {
  background-color: transparent !important;
  border: solid 1px #fff;
  color: #fff !important;
}
#modalForm .modal-content .modal-body button {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  display: block;
  margin: 0px auto;
  background-color: transparent;
  border: solid 1px #fff;
  color: #fff;
}

#modalForm .modal-content .modal-body ::-webkit-input-placeholder {
  /* Edge */
  color: #fff !important;
}

#modalForm .modal-content .modal-body :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
}

#modalForm .modal-content .modal-body ::placeholder {
  color: #fff !important;
}

.cartRightMenu .card-stats.cursor-pointer.card {
  margin-bottom: 0px;
}
.cartRightMenu .card-stats.cursor-pointer.card > button {
  background-color: transparent !important;
  border: none;
  padding: 0px;
}
.cartRightMenu .card-stats.cursor-pointer.card .stats {
  text-align: left;
}

.white-text {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 5px;
}

.paymentTable thead th {
  width: 20%;
}
.paymentTable.table-bordered {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.cartBottomBtns .card-deck {
  margin: 0px;
}
.cartBottomBtns .card-deck button {
  width: 100%;
  background-color: #236499;
  color: #fff;
  border: none;
  margin: 0px;
  padding: 10px 0px;
  border-radius: 4px;
}
.cartBottomBtns .card-deck button:disabled {
  background-color: #143d5e;
  color: #3480bf;
}
.cashPaymentDetails {
  color: #fff;
}

input,
.form-control {
  background-color: transparent !important;
  border: solid 1px rgba(255, 255, 255, 0.3);
  /* color: #fff !important; */
}

.cartRightMenu .card-text {
  font-size: 1.4rem;
}

body {
  /*color: rgba(255, 255, 255, 1) !important;*/
}
.modal-content {
  background-color: #212121 !important;
}
.modal-content .modal-header .modal-title {
  color: #fff !important;
  font-size: 24px;
  margin-bottom: 20px;
}
.modal-content label {
  color: #fff;
}
.modal-content .form-control {
  border-color: #495784;
}
.modal-content .modal-header {
  background-color: #444;
}
body .modal .modal-header .close,
.close > span:not(.sr-only) {
  color: #fff !important;
}

.white-content .react-grid-item.cssTransforms .text-white {
  /*color: #ffffff !important;*/
}
.white-content .react-grid-item .text-white {
  /*color: #ffffff !important;*/
}
.white-content .text-white {
  /*color: #fff !important;*/
}
.white-content .btn-dark-blue-color.text-white {
  /*color: #fff !important;*/
}
.white-content .text-success {
  color: #0f9a20 !important;
}
.white-content .react-grid-item.bg-danger.text-white,
.white-content .react-grid-item.bg-danger .text-white {
  color: #fff !important;
}

.paymentTableScroll {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.search-bar.input-group {
  width: auto !important;
}
.navbar .notifiactionNav .btn {
  margin: 0;
  padding: 0px 10px !important;
}
.search-bar.input-group {
  height: auto !important;
}
.search-bar.input-group i {
  font-size: 16px !important;
  color: inherit!important;
  margin-top: 2px !important;
}
.notifiactionNav {
  margin-top: 1px !important;
}
.notifiactionNav .nav-link {
  padding: 12px 10px !important;
  margin-top: 0px;
}
.notifiactionNav .nav-link .fs-22px {
  font-size: 16px;
  color: inherit!important;
}

.navbar .navbar-brand {
  margin-left: 0px !important;
}

.custom-select {
  border-color: rgba(255, 255, 255, 0.4) !important;
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.5) !important;
  background-image: none !important;
  position: relative;
}
.custom-select option {
  color: #444 !important;
}

/* .custom-select:before{width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid #f00; position: absolute; content: "";} */

.menuTypeListNav {
  text-align: center;
}
.menuTypeListNav .btn-group-sm .btn {
  font-size: 13px !important;
}
.menuTypeListNav .btn {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.createMenyType .form-control {
  width: calc(100% - 92px);
  display: inline-block;
}
.createMenyType .btn {
  padding: 10px 15px !important;
}

.menuList .btn-block {
  display: inline-block;
  width: auto;
  float: right;
  margin-left: 10px !important;
}
.menuList .form-control {
  width: calc(100% - 80px);
}
.btnEdit,
.btnDelete {
  padding: 6px 4px 0;
  text-align: center;
}
.btnEdit i,
.btnDelete i {
  float: left;
  font-size: 26px;
}

.btnEdit {
  float: right;
}
.btnDelete {
  float: left;
}

.categoryActionBtns {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  padding-top: 5px;
  margin-top: 8px;
}
.cardFooterActions {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  padding-top: 8px;
}

small,
.small {
  color: rgba(255, 255, 255, 0.8);
}

.createCategoryList button {
  float: right;
}

.form-control {
  border-color: rgba(255, 255, 255, 0.4) !important;
}
/* .btn, .navbar .navbar-nav > a.btn{margin:0px !important;} */

.modal-content .modal-body .form-control {
  color: rgba(255, 255, 255, 1) !important;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

*
  .form-group:not(.has-danger):not(.has-success)
  .input-group-prepend
  .input-group-text,
*
  .form-group:not(.has-danger):not(.has-success)
  .input-group-append
  .input-group-text,
*
  .input-group-focus:not(.has-danger):not(.has-success)
  .input-group-prepend
  .input-group-text,
*
  .input-group-focus:not(.has-danger):not(.has-success)
  .input-group-append
  .input-group-text,
.login-page
  .form-group:not(.has-danger):not(.has-success)
  .input-group-prepend
  .input-group-text,
.login-page
  .form-group:not(.has-danger):not(.has-success)
  .input-group-append
  .input-group-text,
.login-page
  .input-group-focus:not(.has-danger):not(.has-success)
  .input-group-prepend
  .input-group-text,
.login-page
  .input-group-focus:not(.has-danger):not(.has-success)
  .input-group-append
  .input-group-text,
.card-login.card-white
  .form-group:not(.has-danger):not(.has-success)
  .input-group-prepend
  .input-group-text,
.card-login.card-white
  .form-group:not(.has-danger):not(.has-success)
  .input-group-append
  .input-group-text,
.card-login.card-white
  .input-group-focus:not(.has-danger):not(.has-success)
  .input-group-prepend
  .input-group-text,
.card-login.card-white
  .input-group-focus:not(.has-danger):not(.has-success)
  .input-group-append
  .input-group-text {
  border-color: #495784 !important;
  /* border-color:  rgb(211, 207, 207) !important */
}

.preparationTime .input-group-prepend {
  border: solid 1px #495784;
  border-left: none;
  margin: 0px;
  padding: 0 10px;
  border-radius: 0 0.4285rem 0.4285rem 0;
}
.preparationTime .input-group-prepend i {
  border: none;
  padding: 0px !important;
  margin: 0px !important;
}

.searchIngredients .input-group-append.cursor-pointer {
  border: solid 1px #495784;
  border-left: none;
  margin: 0px;
  padding: 0 10px;
  border-radius: 0 0.4285rem 0.4285rem 0;
}
.searchIngredients .input-group-append.cursor-pointer span {
  border: none;
  padding: 0px !important;
  margin: 0px !important;
}
.text-muted {
  color: #9ca8b3 !important;
}

.table.table-borderless.table-hover td {
  border: solid 1px rgba(255, 255, 255, 0.1) !important;
  padding: 6px 7px !important;
}
.table.table-borderless.table-hover + div > .btn {
  margin: 5px 5px !important;
}

body .border {
  border-color: rgba(255, 255, 255, 0.2) !important;
}
body .toGoLayout .border {
  border-color: rgba(255, 255, 255, 0.2) !important;
  height: 100%;
}
.togoCardSec.card {
  margin-bottom: 0px;
}

.increaseDecrease {
  padding-top: 6px;
}
.cartRightSOScrollSec {
  height: calc(100vh - 140px);
  overflow: auto;
}

.cartRightScrollSec {
  height: calc(100vh - 86px);
  overflow: auto;
}
.cartRightScrollSec.cartRightScrollSecTogo {
  height: calc(100vh - 81px);
}

.toGoLayout {
  padding: 8px;
}

.scroll-bar {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tab-pane.generalTabSec {
  min-height: 382px;
}

.oddColorRow {
  background-color: rgba(0, 0, 0, 0.14);
}
.CreateMenuCardSec .custom-select {
  border: solid 1px rgba(255, 255, 255, 0.4) !important;
  text-align: left;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-size: 0.75rem !important;
}
.CreateMenuCardSec .custom-select.dropdown-toggle:after {
  float: right;
  margin-top: 6px;
}
.CreateMenuCardSec .input-group-prepend,
.searchIngredients .input-group-append.cursor-pointer {
  border: solid 1px rgba(255, 255, 255, 0.4) !important;
}

.custom-control {
  padding-left: 1.8rem !important;
}
.custom-control-label::before,
.custom-control-label::after {
  left: -1.8rem !important;
}
.custom-control-inline {
  margin-right: 1.5rem;
}

.CreateMenuCardSec .igpPrice span {
  border: none !important;
  padding: 0.5rem 0.7rem !important;
}
.CreateMenuCardSec .igpPrice {
  border-radius: 6px 0px 0px 6px !important;
}

.allDeliveriesTable {
  color: rgba(255, 255, 255, 0.7) !important;
}
.allDeliveriesTable td button {
  min-width: 110px;
}
.padiTransSecRight {
  float: right;
}
.padiTransSecRight .form-control {
  padding: 0.5rem 0.5rem !important;
}
.padiTransSecRight .rdt.form-control input {
  width: 150px;
  border-color: #444 im !important;
}
.padiTransSecRight .rdt.form-control1 input {
border-color: #444 !important;
}
.white-content .rdt.form-control1 input{
  color: #444 !important;
}
.cartScrollArea {
  height: calc(100vh - 412px);
  overflow-y: auto;
}
.cartScrollArea.cartScrollArea1 {
  height: calc(100vh - 438px);
  overflow-y: auto;
}
.unProcessScrollMsg {
  height: calc(100vh - 380px);
  overflow-y: auto;
}
.unProcessScrollMsg.unProcessScrollMsg1 {
  height: calc(100vh - 438px);
  overflow-y: auto;
}

.rewardInputBtns .form-group {
  display: inline-block;
  margin: 0px 15px;
}
.rewardInputBtns .form-group .form-control {
  width: 200px;
  display: inline-block;
}

.btn-dark.btn-secondary {
  background: #000 !important;
  background-image: linear-gradient(
    to bottom left,
    #000,
    #000,
    #000
  ) !important;
}

#multiSelect .custom-select {
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}
.rpButton {
  height: 84px;
}

.gemeraCistBtns button {
  margin-left: 15px;
}
.gemeraCistBtns button {
  width: auto !important;
}

.react-select .react-select__control,
.react-select .react-select__control:hover {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

/******************** Theme White Colors **************/
.white-content small,
.white-content .small {
  color: #444;
}
.white-content .react-select .react-select__control,
.react-select .react-select__control:hover {
  border-color: #444 !important;
}
.white-content .react-select
.react-select__control
.react-select__value-container--has-value
.react-select__single-value {
color: #444 !important;
}

.white-content .modal-content .modal-body .form-control {
  border: solid 1px rgba(0, 0, 0, 1);

  color: rgba(0, 0, 0, 1) !important;
}
/* #modalForm .modal-content .modal-body .form-control {
  background-color: transparent !important;
  border: solid 1px #fff;
  color: #fff !important;
} */
.white-content .form-control::-webkit-input-placeholder {
  /* Edge */
  color: rgba(0, 0, 0, 1) !important;
}
.white-content .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.5) !important;
}
.white-content .form-control::placeholder {
  color: #444 !important;
  /* color: gray; */
}

.white-content .form-control1::placeholder {
  color: #444 !important;
  /* color: gray; */
}

.white-content .modal-content {
  background-color: #fff !important;
}
.white-content .modal-content .modal-header .modal-title {
  color: #444 !important;
}
.white-content .modal-content label {
  color: #222a42;
}
body.white-content {
  /*color: #fff;*/
  background: rgb(221, 221, 221) !important;
}
/* body.white-content #root{background: rgb(221, 221, 221) !important;} */
:not(:root):fullscreen::backdrop {
  display: none;
}
.white-content .custom-select {
  color: #fff !important;
  color: #444 !important;
  font-size: 13px;
}

.white-content .form-control {
  border-color: rgb(211, 207, 207) !important;
  /* color: rgba(0, 0, 0, 0.9) !important; */
  color: #ffffff;
}
.white-content .form-control1 {
  border-color: #444 !important;
  color: #444 !important;
  /* color: rgba(0, 0, 0, 0.9) !important; */
}
.white-content .inputBox{
  border-color: #444 !important;
}
.white-content #multiSelect .dropdown .custom-select {
  color: #444 !important;
}

.white-content .card .card-body .card-description,
.white-content .card .card-body .card-category {
  color: #ffffff !important;
}
.white-content .input-group-append .input-group-text,
.white-content .input-group-prepend .input-group-text {
  color: #fff;
}
.white-content .inputBoxColor{
  border-color: #444  !important;
  color: #444;
}

.white-content .toGoCart {
  background: #fff;
}
body.white-content .toGoLayout .border {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
body.white-content .tableHeader {
  background-color: #ccc;
  color: #444 !important;
}
/* body.white-content .card {background-color: #444 !important;} */
.white-content h1,
.white-content h2,
.white-content h3,
.white-content h4,
.white-content h5,
.white-content h6,
.white-content p,
.white-content ol li,
.white-content ul li,
.white-content pre {
  color: #444 !important;
}
.white-content .modal-content .modal-header {
  background-color: #ccc;
}
body.white-content .modal .modal-header .close,
body.white-content .close > span:not(.sr-only) {
  color: #000 !important;
}

body.white-content .nav-tabs > .nav-item > .nav-link {
  color: #444;
}
body.white-content .nav-tabs > .nav-item > .nav-link:hover {
  border: 1px solid #191f31;
  color: #444;
}

body.white-content .table-secondary,
body.white-content .table-secondary > th,
body.white-content .table-secondary > td {
  background-color: #ccc !important;
  color: #444 !important;
}
.react-select
  .react-select__control
  .react-select__value-container--has-value
  .react-select__single-value {
  color: #fff !important;
}
/* body.white-content .btn-gray.btn-active-color.btn-secondary,
body.white-content .btn-info.btn-secondary { */
  /* background-image: linear-gradient(
    to bottom left,
    #ffffff,
    #f5f5f5,
    #f5f5f5
  ) !important;
  color: #444 !important; */
/* } */
body.white-content .togoSubcategoryBtns .btn-info.btn-secondary {
  color: #fff !important;
}

.btn-secondary.btn-secondary1 {
  background: #222 !important;
}


/* body.white-content .navBarHeader[data="blue"],body.white-content .mainNavbar[data="blue"] {background: linear-gradient(0deg,#fff,#fff) !important;}
body.white-content #collapsibleNavbar .navbar-nav li a{color:#444;     border: solid 1px rgba(0,0,0,0.4);}
body.white-content .navbar-brand .btn i{color:#444;}
body.white-content .notifiactionNav .nav-link .fs-22px, body.white-content .search-bar.input-group i{color:#444;} */
body.white-content .fixed-plugin .dropdown-menu {
  background: linear-gradient(#ffffff, #fff);
}
.visible .dropdown-menu{
  visibility: 'visible';
  opacity: 1;
  min-height: '0px';
max-height: '170px';
overflow-y: 'scroll';
 }
body.white-content .fixed-plugin .adjustments-line .togglebutton .label-switch {
  color: #444;
}

body.white-content .categoryActionBtns {
  border-top: solid 1px rgba(0, 0, 0, 0.05);
}
body.white-content .modal-content label {
  color: #444 !important;
}
body.white-content .cardFooterActions {
  border-top: solid 1px rgba(0, 0, 0, 0.05);
}
body.white-content
  .mclCard.card
  .card-footer
  .cardFooterActionsMenuList
  button {
  background: transparent !important;
}
body.white-content .text-green {
  color: #1ad062 !important;
}

.white-content .addNewBtnSec .btn-info .text-white {
  color: #fff !important;
}

body.white-content .oddColorRow {
  background-color: rgba(0, 0, 0, 0.05);
}
.white-content #multiSelect .dropdown .custom-select:hover,
.white-content #multiSelect .dropdown .custom-select:focus {
  background-color: transparent !important;
  background-image: none !important;
}

.addNewBtnSec {
  width: 92px;
  float: right;
}
.addNewBtnSec .btn {
  margin: 0px !important;
}
.addNewBtnSec1 {
  width: 80px;
}
.addNewBtnSec1 .btn {
  margin-left: 15px !important;
}

.menuTypeListNav .btn-group .btn {
  margin: 0px !important;
}

.togoCardSec.card-stats .numbers {
  text-align: left !important;
}
.togoCardSec.card-stats .numbers h3 {
  font-size: 15px;
  color: #fff;
}

.togoAppetBtns .btn {
  font-size: 16px;
  margin: 0px;
}
.todBtnSec .card {
  margin-bottom: 0px;
}

.todBtnSec {
  padding-left: 11px;
  padding-right: 11px;
}
.todBodyDiv .togoCardSec.card-stats .numbers h3 {
  font-size: 16px;
  text-align: center;
  font: 700;
}
.todBodyDiv .card {
  margin-bottom: 0px;
}

.cartScrollSec {
  height: calc(100vh - 425px);
  overflow-y: auto;
}
body .cartScrollSec {
  color: #fff !important;
}
body.white-content .cartScrollSec {
  color: #444 !important;
}

.tableHeader {
  background-color: #444;
  /* border: solid 1px rgb(107, 106, 106); */
}
.tableHeader1 {
  background-color: #313131;
}

.btn-info,
.modal-footer .btn-info.btn-secondary {
  background: #0071e3 !important;
  background-image: linear-gradient(
    to bottom left,
    #42a1ec,
    #0071e3,
    #42a1ec
  ) !important;
  background-size: 210% 210%;
  background-position: top right;
  background-color: #0071e3 !important;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #ffffff;
  border: solid 1px #256ba3;
}

.togoAppetBtns .btn-info,
.togoMenuSec .btn {
  height: 84px;
}

.cartRightScrollSec .card {
  background: #444;
  margin-bottom: 0px;
}
.btn-gray,
.btn-secondary {
  border-width: 2px;
  border: none;
  position: relative;
  overflow: hidden;
  margin: 4px 1px;
  border-radius: 0.4285rem;
  cursor: pointer;
  background: #444;
  background-image: linear-gradient(
    to bottom left,
    #444,
    #444,
    #444
  ) !important;
  background-size: 210% 210%;
  background-position: top right;
  background-color: #444;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #ffffff;
}

.btn-gray.btn-active-color.btn-secondary {
  background: #444;
  background-image: linear-gradient(to bottom left, #929292, #444) !important;
  background-size: 210% 210%;
  background-position: top right;
  background-color: #444;
  height: 84px;
}

.togoSubcategoryBtns .btn-info.btn.btn-secondary {
  background: #0071e3 !important;
  background-image: linear-gradient(
    to bottom left,
    #42a1ec,
    #0071e3
  ) !important;
  background-size: 210% 210%;
  background-position: top right;
  background-color: #0071e3 !important;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #ffffff;
}
.togoSubcategoryBtns .btn.btn-secondary {
  height: 84px;
}
.togoCardSec.card {
  background: #444;
}

.card {
  /*background-color: #444 !important;*/
}
.card-stats hr {
  /*border-color: rgba(255, 255, 255, 0.1) !important;*/
}

.form-check .form-check-label {
  padding-left: 18px !important;
}
.form-check input[type="checkbox"],
.radio input[type="radio"] {
  opacity: 1 !important;
  position: absolute !important;
  visibility: visible !important;
}
/* .modal-content label {color: rgba(255, 255, 255, 0.70) !important;} dropdown li is affecting */

.menuTypeListNav .btn,
.menuTypeListNav .navbar .navbar-nav > a.btn {
  background-image: linear-gradient(
    to bottom left,
    #444,
    #555,
    #777
  ) !important;
  background-color: #444;
  background: #444;
}
.menuTypeListNav .btn.btn-info {
  background-image: linear-gradient(
    to bottom left,
    #1d8cf8,
    #3358f4,
    #1d8cf8
  ) !important;
  background-color: #444;
  background: #444;
}

.text-green {
  color: #1ad062 !important;
}
.cardFooterActionsMenuList {
  padding-top: 0px;
}
.mclCard.card .card-footer .cardFooterActionsMenuList button {
  margin: 0px !important;
}

.stats.float-left {
  padding: 7px 0px 0px 15px;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #444 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.amfCustomFileUp .custom-file-upload {
  width: 100%;
  height: 70px;
  padding-top: 24px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  border-radius: 6px;
}
.my-custom-scrollbar {
  height: 320px !important;
}

.white-content .CreateMenuCardSec .input-group-prepend,
.white-content .searchIngredients .input-group-append.cursor-pointer,
.white-content .CreateMenuCardSec .custom-select {
  border-color: #444 !important;
  color: #fff !important;
}

.createMenuTable thead th,
.createMenuTable tbody td {
  padding: 5px 7px;
}
.createMenuTable .form-group .form-control,
.createMenuTable .input-group .form-control {
  padding: 4px 18px 4px 18px;
  height: 28px;
}
.createMenuTable .custom-select {
  height: 28px;
  padding: 0.2rem 1.7rem 0.2rem 0.7rem !important;
}
.createMenuTable .input-group {
  margin-bottom: 0px;
}

.createMenuTable.table > thead > tr > th,
.createMenuTable.table > tbody > tr > th,
.createMenuTable.table > tfoot > tr > th,
.createMenuTable.table > thead > tr > td,
.createMenuTable.table > tbody > tr > td,
.createMenuTable.table > tfoot > tr > td {
  padding: 5px 7px;
}

.table {
  color: rgba(255, 255, 255, 0.7) !important;
}

/************* Dine To Go Layout Styles Start Here *********/
.dineToGoLayout {
  padding: 8px;
}
.dineInOrderDetailsBtns .card-body {
  border-radius: 6px;
}
.floorBtns {
  padding: 15px 15px 0px 8px;
}
.floorBtns .btn {
  float: left;
}

.dineInFloorModal input.number-input{
  font-size: 24px;
  height: 50px;
  font-weight: bold;
}
.dineInFloorModal .btn.btn-light.button-rounded.btn {
  border-radius: 100px;
  background: transparent;
  background-image: none !important;
  border: solid 1px #fff;
}
.white-content .dineInFloorModal .btn.btn-light.button-rounded.btn {
  border-radius: 100px;
  background: transparent;
  background-image: none !important;
  border: solid 1px #000;
  color: #000;
}
.white-content .dineInFloorModal .btn.btn-light.button-rounded.btn:hover {
  border-radius: 100px;
  background: transparent;
  background-image: none !important;
  border: solid 1px #000;
  color: #fff;
}
.dineInFloorModal .modal-content .modal-body .form-control {
  border-radius: 100px;
}

.scrollHeightSO {
  height: calc(100vh - 470px);
}
.scrollHeight {
  height: calc(100vh - 435px);
}
.height84 {
  height: 84px;
}
#sortable {
  width: 100%;
}

.cvpSelect {
  display: inline-block;
  width: calc(100% - 300px);
}

.contactLocationOverflow {
  max-height: calc(100vh - 160px);
  overflow: auto;
  overflow-x: hidden;
}
.contactLocationOverflow .form-control {
  height: calc(2rem + 2px);
}

.todBtnSec.toGoBtnSec button {
  margin: 0px !important;
}

.reportMainBtns {
  justify-content: center;
}
.tableBgGray {
  background: #353535;
}
.tableBgGray h4 {
  background: #292929;
  padding: 15px 15px;
}
.tableBgGray table th,
.tableBgGray table td {
  padding: 12px 15px !important;
}
.transactionTable thead.btn-blue-color {
  background-color: transparent;
}
.transactionTable tbody td {
  /* padding: 8px 15px !important; */
}

.transctionSearchSec .uuidSearch {
  float: right;
  padding-top: 4px;

}
.transctionSearchSec .btn {
  float: right;
  width: 100px;
}
.transctionSearchSec .form-control {
 border-color: #444  !important;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1rem;
  margin-bottom: -0.0625rem;
  background-color: transparent !important;
  border: 0.0625rem solid #e9ecef;
  color: rgba(255, 255, 255, 0.7) !important;
}

.passcodeLayoutModal h5 {
  margin-bottom: 6px !important;
}
.passcodeBtnsSec .btn {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 2rem !important;
  height: 2rem !important;
}
.passcodeBtnsSec .btn-default {
  background: transparent;
  border: solid 1px #fff;
}
.modal-content .modal-body .enterPasscode {
  color: #fff;
  margin: 0 0 15px;
}
.passcodeLayoutModal .button-rounded {
  border-radius: 100px !important;
  background-color: transparent !important;
  border: solid 1px #fff;
  background-image: none !important;
  margin-bottom: 10px;
}
.enterDetailsCheck input {
  float: left;
  margin-top: 2px;
}

.itemNameTh {
  width: 50%;
}
.estimationPriceValue {
  margin-right: 15px;
}
.splitInput {
  height: calc(1.5rem + 2px) !important;
}
.enployeeUploadImg img {
  max-width: 70px;
  height: 70px;
  width: 100%;
}
.enployeeUploadImg {
  border: solid 1px #fff;
  min-width: 80px;
  padding: 6px;
  position: relative;
  display: inline-block;
  border-radius: 4px;
  width: 100%;
  max-width: 80px;
}

.employeeNoImageThumb {
  border: solid 1px #fff;
  width: 74px;
  height: 74px;
  padding: 6px;
  position: relative;
  display: inline-block;
  border-radius: 4px;
}
.itemNameTh {
  width: 50%;
}
.estimationPriceValue {
  margin-right: 15px;
}
.splitInput {
  height: calc(1.5rem + 2px) !important;
}

/* .lauUploadImage{border:solid 1px #ccc; width:100px; height:100px;} */
.lauUploadImage {
  border: solid 1px #ccc;
  widows: 100%;
  height: 100px;
}
.lauUploadImage {
  top: 0px;
  right: 52px;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
}

.white-content .todBtnSec .btn,
.white-content .todBtnSec .navbar .navbar-nav > .todBtnSec a.btn {
  background: #fff !important;
  background-image: linear-gradient(
    to bottom left,
    #fff,
    #fff,
    #fff
  ) !important;
  background-size: 210% 210%;
  background-position: top right;
  background-color: #fff;
  color: #344675 !important;
}

.selectTableSelectBox #multiSelect .checkbox label {
  color: #000 !important;
}
.selectTableSelectBox #multiSelect .dropdown .custom-select {
  text-align: left;
}
.selectTableSelectBox #multiSelect .dropdown .custom-select:after {
  float: right;
  margin-top: 7px;
}
.white-content #multiSelect .custom-select,
.white-content .custom-select {
  border: 1px solid #444 !important;

  /* border: 1px solid rgb(216, 211, 211) !important; */
}
.white-content .responsiveTable table {
  background: #ffffff;
}

.white-content .form-control1{
border-color: #444 !important;
}
.white-content .menuCatThumbImg {
}
.white-content .table {
  /* background: #fff !important; */
}
.rdtPicker .rdtMonths input,
.rdtPicker .rdtMonths .form-control,
.rdtMonths .rdtMonth,
.rdtMonths .rdtYear,
.rdtYears .rdtMonth,
.rdtYears .rdtYear {
  color: #000 !important;
}
.cashRegisterCard {
  border-radius: 10px !important;
  border: solid 1px #ccc !important;
}
.totalAmountAddcash {
  background: #0000002e;
  padding-top: 9px;
  border-radius: 0.4285rem;
}

@media only screen and (max-width: 1366px) {
  .cartScrollArea.cartScrollArea1 {
    height: calc(100vh - 438px);
  }
  .card.togoCardSec .card-body {
    padding-left: 8px;
    padding-right: 8px;
  }

  .unProcessScrollMsg.unProcessScrollMsg1{
    height: calc(100vh - 438px);
  }
}

@media only screen and (max-width: 1269px) {
  .cartRightMenu .card-text {
    font-size: 1rem;
  }
  #collapsibleNavbar .navbar-nav li a {
    width: auto;
  }
  .scrollCart {
    height: calc(100vh - 464px);
  }
}

@media only screen and (max-width: 1100px) {
  .togoAppetBtns .col-5-row,
  .togoMenuSec .col-5-row {
    width: 33.33% !important;
  }
  ul.notifiactionNav {
    padding-left: 0px;
  }

  .todBodyDiv .togoCardSec.card-stats .numbers h3 {
    font-size: 14px;
  }
  .card.togoCardSec .card-body {
    padding-left: 4px;
    padding-right: 4px;
  }
  h3,
  .h3 {
    font-size: 1rem !important;
  }
  .cartScrollArea.cartScrollArea1,
  .cartScrollArea.cartScrollAreaTogo {
    height: calc(100vh - 438px);
  }
  .scrollHeight {
    height: calc(100vh - 438px);
  }

  .menuTypeListNav .btn {
    padding-left: 7px !important;
    padding-right: 7px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .card.togoCardSec h3,
  .card.togoCardSec .h3 {
    font-size: 1rem !important;
  }
  /* .cartRightScrollSec.cartRightScrollSecTogo {height: calc(100vh - 96px);} */
  .cartRightScrollSec.cartRightScrollSecTogo {
    height: calc(100vh - 906px);
  }
}

@media only screen and (max-width: 992px) {
  .txt {
    font-size: 20px;
    text-align: center; /* margin-top: 10px; */
    font-family: "Lato", sans-serif;
    line-height: 30px;
  }
  /* .num {height: 60px; width: 60px;}
    .span4 {width: 80px;  float: left;  margin: 0 -23px 10px 8px;} */

  .num-pad {
    width: 248px;
  }
  .span4 {
    width: 60px;
  }
  .num-pad .num {
    height: 60px !important;
  }

  body {
    overflow-y: auto;
  }
  .card-body .numbers h3 {
    color: #ffffff;
    font-size: 1rem !important;
  }
  .mainNavbar {
    padding: 0.3rem 0.9375rem !important;
  }
  .mainNavbar .navbar-nav .nav-link {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .navbar .photo {
    float: left;
  }
  .userDropdown .d-lg-none {
    font-size: 22px;
    display: none;
  }
  .userDropdown .dropdown-toggle:after {
    display: none;
  }
  .userDropdown .float-right.dropdown.nav-item .nav-link {
    padding-left: 0px;
  }
  .userDropdown .dropdown-menu .dropdown-item,
  .userDropdown .bootstrap-select .dropdown-menu.inner li a {
    font-size: 1rem !important;
  }

  #collapsibleNavbar .navbar-nav li {
    margin: 6px 6px !important;
  }

  .headerRight {
    justify-content: center;
  }
  .themeChange {
    margin-right: 15px;
  }

  .scrollCart {
    height: 100%;
  }
  .cartBackgroundColor {
    margin-right: 0.5rem !important;
  }

  .menuTypeListNav {
    margin: 15px 0px;
  }
  .menuTypeListNav .btn-group-toggle.btn-group {
    display: flex;
  }
}

@media only screen and (max-width: 992px) {
  .headerRight .nav-link {
    padding: 0px;
  }
  .headerRight .nav-link .headerLogout {
    margin-right: 15px;
  }
  .themeChange .fixed-plugin .fa-cog {
    top: 2px;
  }

  .mainNavbar .navbar-collapse {
    position: absolute;
    top: 44px;
    background-color: #3358f4;
    left: 0px;
    right: 0px;
    padding: 20px;
  }
  .mainNavbar .headerRight .userDropdown .dropdown.show .dropdown-menu {
    position: absolute !important;
    background: #fff;
    top: 36px !important;
    right: -11px !important;
  }
  .mainNavbar
    .headerRight
    .userDropdown
    .dropdown.show
    .dropdown-menu
    .nav-item.dropdown-item {
    color: #000 !important;
  }

  .navbar .themeChange .fixed-plugin .dropdown.show .dropdown-menu {
    position: absolute !important;
    background: #fff;
    width: 280px;
    right: -30px;
  }
  .white-content .themeChange .fixed-plugin .dropdown.show .dropdown-menu {
    position: absolute !important;
    background: #222a42;
    width: 280px;
    right: -30px;
  }
  .themeChange .fixed-plugin .dropdown .dropdown-menu:after {
    right: 15px;
  }

  .responsiveCart .col-md-1.col-lg-1.py-2.col-sm-1 {
    display: none;
  }
  .priceColoum {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .headerRight .nav-link {
    padding: 0px;
  }
  .headerRight .nav-link .headerLogout {
    margin-right: 15px;
  }
  .themeChange .fixed-plugin .fa-cog {
    top: 2px;
  }

  .mainNavbar .navbar-collapse {
    position: absolute;
    top: 44px;
    background-color: #3358f4;
    left: 0px;
    right: 0px;
    padding: 20px;
  }
  .mainNavbar .headerRight .userDropdown .dropdown.show .dropdown-menu {
    position: absolute !important;
    background: #fff;
    top: 36px !important;
    right: -11px !important;
  }
  .mainNavbar
    .headerRight
    .userDropdown
    .dropdown.show
    .dropdown-menu
    .nav-item.dropdown-item {
    color: #000 !important;
  }

  .navbar .themeChange .fixed-plugin .dropdown.show .dropdown-menu {
    position: absolute !important;
    background: #fff;
    width: 280px;
    right: -30px;
  }
  .white-content .themeChange .fixed-plugin .dropdown.show .dropdown-menu {
    position: absolute !important;
    background: #222a42;
    width: 280px;
    right: -30px;
  }
  .themeChange .fixed-plugin .dropdown .dropdown-menu:after {
    right: 15px;
  }

  .responsiveCart .col-md-1.col-lg-1.py-2.col-sm-1 {
    display: none;
  }
  .priceColoum {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .responsiveCart {
    width: 600px;
  }
  .responsiveCart {
    overflow-x: auto;
    width: auto;
  }
  .responsiveCart .cartBackgroundColor {
    width: 600px;
  }
  .responsiveCart .cartBackgroundColor .col-md-2.py-2.col-lg-2.col-sm-3,
  .responsiveCart .cartBackgroundColor .col-md-7.col-lg-7.py-2.col-sm-5,
  .responsiveCart
    .cartBackgroundColor
    .row.mx-0.table-secondary
    > div:first-child {
    width: 20%;
  }
  .responsiveCart .cartBackgroundColor .col-md-2.py-2.col-lg-2.col-sm-3,
  .responsiveCart .cartBackgroundColor .col-md-7.col-lg-7.py-2.col-sm-5,
  .responsiveCart
    .cartBackgroundColor
    .row.mx-0.table-secondary
    > div:nth-child(2) {
    width: 60%;
  }
  .responsiveCart .cartBackgroundColor .col-md-2.py-2.col-lg-2.col-sm-3,
  .responsiveCart .cartBackgroundColor .col-md-7.col-lg-7.py-2.col-sm-5,
  .responsiveCart
    .cartBackgroundColor
    .row.mx-0.table-secondary
    > div:nth-child(4) {
    width: 20%;
  }

  .responsiveCart
    .cartBackgroundColor
    .scroll-bar.scrollCart
    .row.mx-0.text-dark
    > div:first-child {
    width: 20%;
  }
  .responsiveCart
    .cartBackgroundColor
    .scroll-bar.scrollCart
    .row.mx-0.text-dark
    > div:nth-child(2) {
    width: 60%;
  }
  .responsiveCart
    .cartBackgroundColor
    .scroll-bar.scrollCart
    .row.mx-0.text-dark
    > div:nth-child(5) {
    width: 20%;
  }

  .responsiveCart .cartBackgroundColor .row.mx-0.py-2 > div:first-child {
    width: 8%;
  }
  .responsiveCart .cartBackgroundColor .row.mx-0.py-2 > div:nth-child(2) {
    width: 18%;
  }
  .responsiveCart .cartBackgroundColor .row.mx-0.py-2 > div:nth-child(3) {
    width: 22%;
  }
  .responsiveCart .cartBackgroundColor .row.mx-0.py-2 > div {
    width: 25%;
  }
}

.btn-width-100px {
  width: 100px !important;
}

.btn-brown-color {
  background-color: #800000;
  color: white;
}

.btn-brown-color:hover {
  background-color: #570202;
  color: white;
}

.sa-btn button {
  border-radius: 30px !important;
  border: 1px solid #888686 !important;
  margin: 4px 7px;
}
.sa-text {
  color: #fff;
}
.white-content .sa-password-text .btn-default {
  border: 1px solid #000;
}
.white-content .sa-password-text .btn-success {
  border: 1px solid #fff;
}

.white-content .sa-bg-2 .card:not(.card-white) {
  box-shadow: 0 1px 15px 0 rgba(123, 123, 123, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.white-content .table > tbody > tr > th {
  color: rgba(34, 42, 66, 0.7) !important;
}
.sa-card {
  min-height: 300px;
}
.sa-custom-modal-style {
  max-width: 80% !important;
}

.sa-btn-model {
  float: right;
  width: min-content !important;
}
@media (min-width: 1024px) {
  .col-5-row {
    width: 20%;
  }
  .order-title{
    width: 25%;
  }
  .cart-order-top-header{
    width: 20%;
  }
  .modal-size{
    min-width: 1000px;
  }
}

@media (max-width: 768px) {
  .col-5-row {
    width: 33.33%;
  }
  .order-title{
    width: 33.33%;
  }
  .cart-order-top-header{
    width: 16.66%;
  }
  .modal-size{
    min-width: 700px;
  }

 
}

@media (max-width: 540px) {
  .col-5-row {
    width: 50%;
  }
  .order-title{
    width: 33.33%;
  }
  .cart-order-top-header{
    width: 20%;
  }
  .modal-size{
    min-width: 700px;
  }

  
}

.text-grey {
  color: rgb(102, 100, 100);
}
.btn-height-3-3px {
  height: 3.3rem;
}
.btn-height-84px {
  height: 84px;
}
.center {
  text-align: center;
  border: 3px solid green;
}

.tableResponsive {
  width: 100%;
  border: solid 0.1px rgba(255, 255, 255, 0.1) !important;
}
.tableResponsive table {
  background: #464545;
}
.tableResponsiveLargetable {
  min-width: 1050px;
}

.btn-black-color.btn-black-color1 {
  background: rgb(14, 13, 13) !important;
  border: 1.75px solid #c3c2c2af;
}
.btn-primary-color.btn-primary-color1 {
  background: #0071e3 !important;
  border: 2px solid #0965c2;
  /* color: #fff; */
}
.text-white-color {
  color: #ffffff;
}

.text-color {
  color: #757679;
}

.cursor-not-allowed{
  cursor: not-allowed;
}
.h-50{
  height: 50%;
}
.text-gray{
  color: white
}

.fs-12px{
  font-size: 12px;
}
.fs-14px{
  font-size: 14px;
}
.fs-16px{
  font-size: 16px;
}
.fs-20px{
  font-size: 20px;
}
.fs-24px{
  font-size: 24px;
}
.fs-35px{
  font-size: 35px;
}
.white-content .bg-white1{
  background-color: white;
  color: #000;
}

.white-content .amfCustomFileUp .custom-file-upload {
  width: 100%;
  height: 70px;
  padding-top: 24px;
  text-align: center;
  border: 1px solid #444 !important;
  border-radius: 6px;
}

.w-56{
  width:56%;
}
.w-20{
  width: 20px;;
}
.w-25{
  width: 25px;;
}
.fs-10px{
  font-size: 10px;
}
.text-gray{
  color: rgb(216, 211, 211) !important;
}
.white-content .text-info{
  color: #1d8cf8  !important;
}
body.white-content {
  color: #000000!important;

}
.white-content .text-gray{
  color: rgb(216, 211, 211) !important;
}
.white-content .nav-tabs > .nav-item > .nav-link.active{
  border: 1px solid #444 !important;
}
.white-content .border-dashed{
  border: 1px dashed rgb(216, 211, 211) !important;;
}
.borderBottomColor{
    border-bottom: 1px solid rgb(55 61 97) !important;
}
.borderTopColor{
  border-top: 1px solid rgb(123, 122, 122) !important;
}
.white-content .borderTopColor{
border-top: 1px solid rgb(181, 176, 176) !important;
}
.white-content .borderBottomColor{
  border-bottom: 1px solid rgb(181, 176, 176) !important;
}
.white-content .border-color{
  border: 1px solid rgb(216, 211, 211) !important;;
}
.white-content .tableResponsive {
  width: 100%;
  border: solid 0.1px rgba(216,211,211) !important;
}
.white-content .tableResponsive table {
  background-color: #fff;
  color: #000;
}
.tableBorderColor{
  /* border-color: rgba(255, 255, 255, 0.4) !important; */
  border-top: 0.1px solid rgb(123, 122, 122) !important;

}


.borderColor{
  border: 1px solid gray !important;
}
.postal{

    width: 100%;
    display: block;
    line-height: 1.5;
    padding: 13px 10px 13px 10px;
      font-size: 100%;
      font-family: Lato, Helvetica, Arial, sans-serif;
      margin: 0;
      background: #fff;
      border: 1px solid #e1e1e1;
      outline: none;
      -webkit-transition: border-color linear .3s;
      -moz-transition: border-color linear .3s;
      -o-transition: border-color linear .3s;
      transition: border-color linear .3s;
      box-sizing: border-box;
      -webkit-appearance: none;
      border-radius: 0;
    background-color: #ffffff;
      color: #3c2311;
      border-color: #e1e1e1;
  }
  .postal::-webkit-input-placeholder {
    /* Edge */
    color: black !important;
    font-size: 16px;
  }

  /* -ms-input-placeholder */


  text-uppercase{
    text-transform: uppercase;
  }
  
  .fs-17px{
    font-size: 17px;
  }
  


  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /* background-color: red; */
    color: white;
    text-align: center;
 }

 .home-logo{
    position: absolute;
    right: 171px !important;
    border-radius: 8px 0 0 8px;
    text-align: center;
    top: 17px !important;
    font-size: 9px !important;
 }

 .logo-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

footer {
  /* background-color: #777; */
  padding: 10px;
  text-align: center;
  color: white;
}
#dropdown-scroll{
  visibility: visible;
  opacity: 1;
  min-height: 0px;
  max-height: 170px;
  overflow-y: scroll;
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .modal-dialog-1{
    min-width: 500px;
  }

}
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .modal-dialog-1{
    min-width: 1000px;
  }

}
@media screen and (min-width: 1100px) and (max-width: 2560px) {
  .modal-dialog-1{
    min-width: 1300px;
  }

}

.TableSticky {
  .table {
    font-size: 12px;
    // border: 1px solid #2a324b;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 10px;
      border-bottom: 1px solid hsl(225, 28%, 23%);
      // border-right: 1px solid #2a324b;
      // background-color: #1a223b;
      background-color: #27293d;
      overflow: hidden;
      width: 400px;
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.9rem;
      text-align :center;
      :last-child {
        // border-right: 0;
      }
    }
    .th {
      font-weight: 700;
      border-bottom: 2px solid #2a324b;
      text-transform: uppercase;
      font-size: 12px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #202339;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #202339;
        padding: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 4px 0px 5px #202339;
      }

      [data-sticky-first-right-td] {
        box-shadow: -4px 0px 3px #202339;
      }
    }
  }
}

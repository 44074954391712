
@font-face {
    font-family: 'font-regular';
    src: url(../fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'font-medium';
    src: url(../fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'font-semibold';
    src: url(../fonts/Lato-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'font-light';
    src: url(../fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'font-bold';
    src: url(../fonts/Lato-Black.ttf) format('truetype');
}

/** ==================================
--------------Font family--------------
======================================*/
.font-light {
    font-family: font-light;
}
.font-regular {
    font-family: font-regular;
}
.font-medium {
    font-family: font-medium;
}
.font-semibold {
    font-family: font-semibold;
}
.font-bold {
    font-family: font-bold;
}


/** ==================================
--------------Text Sizes--------------
======================================*/
.text-xxsmall {
    font-size: 0.5714rem;
}
.text-xsmall {
    font-size: 0.7143rem;
}
.text-small {
    font-size: 0.8571rem;
}
.text-regular {
    font-size: 1rem;
}
.text-medium {
    font-size: 1.143rem;
}
.text-large {
    font-size: 1.286rem;
}
.text-xlarge {
    font-size: 1.429rem;
}
.text-hlarge {
    font-size: 1.714rem;
}
